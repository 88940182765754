<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu-copy.vue
  FILE_NAME : modal-menu-copy
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : 메뉴 복사 팝업 컴포넌트
-->
<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="conts-row">
        <div class="conts-box">
          <br />
          <div class="conts-title">선택한 메뉴명</div>
          <div class="conts-input">
            {{ formData.menuFormData.data.menuNm }}
          </div>
        </div>
        <div class="conts-box">
          <div class="conts-title">하위메뉴 복사</div>
          <div class="conts-input">
            <DxCheckBox v-model="formData.isCopyChildren"></DxCheckBox>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import DxCheckBox from 'devextreme-vue/check-box';

  export default {
    components: {
      DxCheckBox,
    },
    props: {
      contentData: Object,
      iconData: Array,
      menuList: Array,
    },
    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        formData: {
          isCopyChildren: true,
        },
        limitLength: {},
        listData: [],
        messageData: null,
      };
    },
    computed: {
      dept3Menu() {
        if (this.formData.menuDepth === 4) {
          return this.menuList.find(d => d.id === this.formData.parentId && d.menuDepth === 3);
        } else {
          return null;
        }
      },
      dept2Menu() {
        if (this.dept3Menu) {
          return this.menuList.find(d => d.id === this.dept3Menu.parentId && d.menuDepth === 2);
        } else {
          return this.menuList.find(d => d.id === this.formData.parentId && d.menuDepth === 2);
        }
      },
      dept1Menu() {
        if (this.dept2Menu) {
          return this.menuList.find(d => d.id === this.dept2Menu.parentId && d.menuDepth === 1);
        } else {
          return this.menuList.find(d => d.id === this.formData.parentId);
        }
      },
    },
    methods: {
      createdData() {
        this.formData = { ...this.formData, ...this.contentData };
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
    destroyed() {},
  };
</script>

<style scoped>
  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .conts-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .conts-row .conts-box {
    display: inline-block;
  }

  .conts-row .conts-box > div {
    display: inline-block;
  }

  .conts-row .conts-box .conts-title {
    width: 120px;
    display: inline-block;
    vertical-align: top;
  }

  .conts-row .conts-box .conts-input {
    display: -webkit-inline-box;
  }

  .conts-row .conts-box .conts-input .limitLength-box {
    margin-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }
</style>
<style>
  #modal_menu_config_menuicon .dx-texteditor-input {
    font-size: 15px;
    padding-top: 0px;
    padding-left: 2px;
  }
</style>
